import axios, { AxiosResponse, AxiosRequestConfig } from "axios"
import { AnyAction, Dispatch } from "redux"
import config from "config"
import { formatUrl } from "utils/urls"
import { getToken } from "services/tokens"

export type ClientHTTPMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE" | "OPTIONS"
export interface ClientTypes {
  REQUEST: string
  SUCCESS: string
  FAILURE: string
}
export interface ClientOptions {
  data: any
  params: any
}
export interface OnSuccessResponse {
  type?: string
  success: true
  status: number | string
  data?: any
  [key: string]: any
}
export interface OnFailureResponse {
  type?: string
  success: false
  status: number | string
  error?: string | Error
  [key: string]: any
}
export type ApiClientCallbackResponse = OnSuccessResponse | OnFailureResponse
export interface ClientData {
  url: string
  method: ClientHTTPMethod
  types: ClientTypes
  options: ClientOptions
  onSuccess?(res: any): OnSuccessResponse | Promise<OnSuccessResponse>
  onFailure?(res: any): OnFailureResponse | Promise<OnFailureResponse>
}
export interface Client {
  get(url: string, data: any, options: any): Promise<AxiosResponse>
  post(url: string, data: any, options: any): Promise<AxiosResponse>
  put(url: string, data: any, options: any): Promise<AxiosResponse>
  delete(url: string, data: any, options: any): Promise<AxiosResponse>
}

const getClient = (token?: string | undefined | null): Client => {
  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `${config.authorizationPrefix} ${token || ""}` : "",
    },
  }

  return {
    get: (url: string, data: Record<string, any>, options: AxiosRequestConfig = {}) =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (url: string, data: Record<string, any>, options: AxiosRequestConfig = {}) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url: string, data: Record<string, any>, options: AxiosRequestConfig = {}) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (url: string, data: Record<string, any>, options: AxiosRequestConfig = {}) =>
      axios.delete(url, { ...defaultOptions, ...options }),
  }
}

/**
 *
 * @param {String} url - relative api endpoint url
 * @param {String} method - "GET", "POST", "PUT", "PATCH", "DELETE"
 * @param {Object} types - object with three keys representing the different action types: REQUEST, SUCCESS, FAILURE
 * @param {Object} options - object with potential data and query params
 * @param {Function} onSuccess - callback to run with the returned data, if any
 */
const apiClient = ({
  url,
  method,
  types: { REQUEST, SUCCESS, FAILURE },
  options: { data, params },
  onSuccess = (res) => ({ success: true, type: res.type, status: res.status }),
  onFailure = (res) => ({ success: false, type: res.type, status: res.status, error: res.error }),
}: ClientData) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (typeof window === "undefined") return onSuccess({ success: true, type: SUCCESS, status: 200 })
    const token = await getToken()
    const client = getClient(token)

    dispatch({ type: REQUEST })
    const urlPath = formatUrl(url, params)

    try {
      const res: AxiosResponse = await client[method.toLowerCase()](urlPath, data)

      dispatch({
        type: SUCCESS,
        data: res.data,
      })

      return onSuccess({ type: SUCCESS, success: true, status: res.status, data: res.data })
    } catch (err) {
      // console.log({ err })
      const error = err?.response?.data?.message
        ? { ...err.response.data }
        : err?.response?.data
        ? err.response.data
        : err
      dispatch({
        type: FAILURE,
        error,
      })

      return onFailure({ type: FAILURE, success: false, status: err.status, error: err })
    }
  }
}

export default apiClient
