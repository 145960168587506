const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || "development"]

const appConfig = {
  remoteServerBaseUrl: process.env.REMOTE_HOST_URL || `http://localhost:8000/api`,
  authorizationPrefix: process.env.NEXT_PUBLIC_AUTHORIZATION_PREFIX || `Bearer`,
  uiToastDuration: process.env.NEXT_PUBLIC_UI_TOAST_DURATION || 15000,
}

const googleConfig = {
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  googleStorageApiKey: process.env.GOOGLE_MAPS_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  googleOAuthClientID: process.env.GOOGLE_OAUTH_CLIENT_ID || process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
  googleAnalyticsTrackingKey: process.env.GOOGLE_ANALYTICS_KEY || process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY,
  // googleOAuthClientSecret: process.env.GOOGLE_OAUTH_CLIENT_SECRET,
  // googleCloudStorageBucketName: process.env.GOOGLE_CLOUD_STORAGE_BUCKET_NAME,
}

const linkedinConfig = {
  LINKEDIN_STATE: process.env.LINKEDIN_STATE || process.env.NEXT_PUBLIC_LINKEDIN_STATE,
  LINKEDIN_REDIRECT_URI: environment.isProduction
    ? "https://velup.io/linkedin/callback"
    : "http://localhost:3000/linkedin/callback",
  LINKEDIN_CLIENT_ID: process.env.LINKEDIN_CLIENT_ID || process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
  LINKEDIN_SCOPE: "r_liteprofile r_emailaddress w_member_social",
}

const routesConfig = {
  publicPathnames: [
    `/terms-of-service`,
    `/register`,
    `/login`,
    `/signout`,
    `/password-reset`,
    `/contact-us`,
    `/about-us`,
    `/events`,
    `/data-explorer`,
    `/providers`,
    `/linkedin`,
    // ok
  ],
}

const stripeConfig = {
  stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY || process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  stripeClientId: process.env.STRIPE_CLIENT_ID || process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID,
}

export default {
  ...environment,
  ...appConfig,
  ...googleConfig,
  ...linkedinConfig,
  ...routesConfig,
  ...stripeConfig,
}
