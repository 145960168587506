// import initialState from "models/initialState"
import initialState, { StateShape } from "models/initialState"
import { ThunkAction } from "redux-thunk"
import { Dispatch } from "redux"
import Router from "next/router"
import { useUniqueId } from "hooks/common/useUniqueId"
import config from "config"

import { AuthAction, AuthActionType } from "types/auth"
import {
  Toast,
  ToastID,
  UiAction,
  UiActionType,
  NavbarDropdownAction,
  GenericUiAction,
  MobileNavbarAction,
  ModalAction,
  UiToastAction,
  NavbarSearchAction,
} from "types/ui"

/**
 * UI REDUCER
 *
 */
export default function uiReducer(state = initialState.ui, action: UiAction | AuthAction) {
  switch (action.type) {
    case UiActionType.ADD_UI_TOAST:
      return {
        ...state,
        toastList: [...state.toastList, action.toast],
      }
    case UiActionType.REMOVE_UI_TOAST:
      return {
        ...state,
        toastList: state.toastList.filter((t) => t.id !== action.toastId),
      }
    case UiActionType.SET_NAVBAR_SEARCH_VALUE:
      return {
        ...state,
        navbarSearch: action.value,
      }
    case UiActionType.HANDLE_NAVBAR_SEARCH:
      return {
        ...state,
        navbarSearch: "",
      }
    case UiActionType.OPEN_MOBILE_NAV:
      return {
        ...state,
        mobileOpen: true,
      }
    case UiActionType.CLOSE_MOBILE_NAV:
      return {
        ...state,
        mobileOpen: false,
      }
    case UiActionType.OPEN_MODAL:
      return {
        ...state,
        modalOpen: {
          [action.data]: true,
        },
      }
    case UiActionType.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: {
          [action.data]: false,
        },
      }
    case UiActionType.TOGGLE_NAVBAR_DROPDOWN:
      return {
        ...state,
        // Mark everything false except the label - mark that whatever
        // navbarDropdowns: {
        //   ...initialState.ui.navbarDropdowns,
        //   [action.label]: !state.navbarDropdowns[action.label],
        // },
      }
    case UiActionType.OPEN_SINGLE_NAVBAR_DROPDOWN:
      return {
        ...state,
        dropdownMenuOpen: action.label,
        // navbarDropdowns: {
        //   ...initialState.ui.navbarDropdowns,
        //   [action.label]: true,
        // },
      }
    case UiActionType.CLOSE_SINGLE_NAVBAR_DROPDOWN:
      return {
        ...state,
        dropdownMenuOpen: null,
        // navbarDropdowns: {
        //   ...state.navbarDropdowns,
        //   // ...initialState.ui.navbarDropdowns,
        //   [action.label]: false,
        // },
      }
    case UiActionType.CLOSE_ALL_NAVBAR_DROPDOWNS:
      return {
        ...state,
        dropdownMenuOpen: null,
        // navbarDropdowns: initialState.ui.navbarDropdowns,
        mobileOpen: false,
      }
    case UiActionType.SET_SUBMENU_ACTIVE_SELECTION:
      return {
        ...state,
        navbarSubMenu: {
          ...state.navbarSubMenu,
          active: {
            ...state.navbarSubMenu.active,
            [action.subMenu]: action.label,
          },
        },
      }
    case UiActionType.CLEAR_UI_STATE:
      return initialState.ui
    case AuthActionType.SIGN_USER_OUT:
      return initialState.ui
    default:
      return state
  }
}

const removeUiToast = ({ toastId }: { toastId: ToastID }): UiToastAction => ({
  type: UiActionType.REMOVE_UI_TOAST,
  toastId,
})

// not an action - utility function for creating toasts
const createToast = (dispatch: Dispatch, toast: Toast): UiToastAction => {
  const toastId = useUniqueId("toast")

  // setTimeout(() => dispatch(Actions.removeUiToast({ id: toastId })), config.uiToastDuration)
  setTimeout(() => dispatch(removeUiToast({ toastId })), config.uiToastDuration || 15000) // 15 sec
  return dispatch({ type: UiActionType.ADD_UI_TOAST, toast: { ...toast, id: toastId } })
}

const addUiToast = ({ toast }: { toast: Toast }): ThunkAction<UiToastAction, StateShape, void, UiToastAction> => {
  return (dispatch, getState) => {
    const { ui } = getState()
    // TODO: determine if checking for an existing message with the same title is a good idea
    const existingMessage = ui?.toastList?.map((t: Toast) => t.title).indexOf(toast.title) !== -1

    return !existingMessage ? createToast(dispatch, toast) : null
  }
}

/**
 * UI ACTIONS
 */

const clearUiState = (): GenericUiAction => ({ type: UiActionType.CLEAR_UI_STATE })
const openMobileNav = (): MobileNavbarAction => ({ type: UiActionType.OPEN_MOBILE_NAV })
const closeMobileNav = (): MobileNavbarAction => ({ type: UiActionType.CLOSE_MOBILE_NAV })
const openModal = (modalName: string): ModalAction => ({ type: UiActionType.OPEN_MODAL, data: modalName })
const closeModal = (modalName: string): ModalAction => ({ type: UiActionType.CLOSE_MODAL, data: modalName })
const closeSingleDropdown = (label: string): NavbarDropdownAction => ({
  type: UiActionType.CLOSE_SINGLE_NAVBAR_DROPDOWN,
  label,
})
const openSingleDropdown = (label: string): NavbarDropdownAction => ({
  type: UiActionType.OPEN_SINGLE_NAVBAR_DROPDOWN,
  label,
})
const closeAllNavbarDropdowns = (): NavbarDropdownAction => ({ type: UiActionType.CLOSE_ALL_NAVBAR_DROPDOWNS })
const setSubMenuActiveSelection = (subMenu: string, label: string): NavbarDropdownAction => ({
  type: UiActionType.SET_SUBMENU_ACTIVE_SELECTION,
  subMenu,
  label,
})
const setNavbarSearchValue = (value: string): NavbarSearchAction => ({
  type: UiActionType.SET_NAVBAR_SEARCH_VALUE,
  value,
})

export type HandleNavbarSearchResult = ThunkAction<void, StateShape, void, NavbarSearchAction>
const handleNavbarSearch = ({ rootPath }: { rootPath: string }): HandleNavbarSearchResult => {
  return (dispatch, getState) => {
    const { ui } = getState()

    dispatch({ type: UiActionType.HANDLE_NAVBAR_SEARCH })
    return dispatch(Actions.navigateInternal({ path: `${rootPath}?q=${ui.navbarSearch}#event-search` }))
  }
}
/* navigate internal */
export type NavigateInternalResult = ThunkAction<void, StateShape, void, GenericUiAction | NavbarDropdownAction>
const navigateInternal = ({ path }: { path: string }): NavigateInternalResult => {
  return (dispatch) => {
    dispatch({ type: UiActionType.NAVIGATE_INTERNAL, path })
    // close all navbar dropdowns
    dispatch(Actions.closeAllNavbarDropdowns())

    Router.push(path)
  }
}
/* handle dropdown click */
// export type HandleDropdownLabelClickResult = ThunkAction<void, StateShape, void, GenericUiAction | NavbarDropdownAction>
// const handleDropdownLabelClick = (label: string): HandleDropdownLabelClickResult => {
//   return (dispatch, getState) => {
//     const { ui } = getState()

//     // if the dropdown is already open, close it
//     // otherwise open it and create the event listener
//     if (ui.navbarDropdowns[label]) {
//       dispatch(Actions.closeSingleDropdown(label))
//     } else {
//       dispatch(Actions.openSingleDropdown(label))
//     }
//   }
// }

export type HandleDropdownLabelClickResult = ThunkAction<void, StateShape, void, GenericUiAction | NavbarDropdownAction>
const handleDropdownLabelClick = (label: string): HandleDropdownLabelClickResult => {
  return (dispatch, getState) => {
    const { ui } = getState()

    // if the dropdown is already open, close it
    // otherwise open it and create the event listener
    if (ui.dropdownMenuOpen === label) {
      dispatch(Actions.closeSingleDropdown(label))
    } else {
      dispatch(Actions.openSingleDropdown(label))
    }
  }
}

export const Actions = {
  clearUiState,
  openMobileNav,
  closeMobileNav,
  openModal,
  closeModal,
  closeSingleDropdown,
  openSingleDropdown,
  closeAllNavbarDropdowns,
  setSubMenuActiveSelection,
  removeUiToast,
  addUiToast,
  setNavbarSearchValue,
  handleNavbarSearch,
  navigateInternal,
  handleDropdownLabelClick,
}
