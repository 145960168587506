import * as R from "ramda"
import {
  errorMessageToUserFriendlyMessageMapping,
  errorFieldToMessageMapping,
  validationErrorTypeToMessageMapping,
} from "constants/errors"
import { CustomFastAPIError } from "types/errors"
import { isStringOrNumber } from "utils/parseUtils"

export const isCustomFastAPIError = (error: any): error is CustomFastAPIError => {
  if (!error) return false

  if (isStringOrNumber(error)) return false

  if (
    R.all(
      (p) => error.hasOwnProperty(p) // check that error contains all required fields
    )(
      ["message", "raw", "status"] // required props for a custom fastapi error model
    )
  ) {
    return true
  }

  return false
}

export const makeUserFriendlyErrorMessage = (errorMessage) => {
  if (errorMessageToUserFriendlyMessageMapping[errorMessage]) {
    return errorMessageToUserFriendlyMessageMapping[errorMessage]
  }

  if (errorMessage.toLowerCase().indexOf("internal server error") !== -1) {
    return errorMessageToUserFriendlyMessageMapping["Internal server error"]
  }

  return errorMessage
}

export const parseErrorDetail = (errorDetail) => {
  let errorMessage = "Something went wrong. Contact support."

  if (Array.isArray(errorDetail?.loc)) {
    // error with a path parameter and probably isn't a client issue
    if (errorDetail.loc[0] === "path") return errorMessage
    // error with a query parameter and also is probably not the client's fault
    if (errorDetail.loc[0] === "query") return errorMessage
    // because we use FastAPI's `Body(..., embed)` for all post requests
    // this should be an array of length 3, with shape: ["body", "new_user", "email"]
    if (errorDetail.loc[0] === "body") {
      const invalidField = errorDetail.loc?.[2]
      const errorType = errorDetail?.type

      if (errorFieldToMessageMapping[invalidField]) {
        errorMessage = errorFieldToMessageMapping[invalidField]
      } else if (errorDetail?.msg) {
        if (validationErrorTypeToMessageMapping[errorType]) {
          errorMessage = validationErrorTypeToMessageMapping[errorType](invalidField, errorDetail) //  `${invalidField} ${validationErrorTypeToMessageMapping[errorType]}`
        } else {
          errorMessage = errorDetail.msg
        }
      }
    }
  }

  return errorMessage
}

export const extractErrorMessages = (error: any): string[] => {
  const errorList = []

  // if we just pass in a string, use that
  if (typeof error === "string") errorList.push(error)

  if (isCustomFastAPIError(error)) {
    // errorList.push(error.message)
    return [error.message]
  }

  // in the case that we raised the error ourselves with FastAPI's HTTPException,
  // just use the message passed from the backend.
  if (typeof error?.detail === "string") errorList.push(error.detail)

  // in the case that there's a validation error in the request body, path parameters, or query parameters
  // we'll get an array of error issues here:
  if (Array.isArray(error?.detail)) {
    error.detail.forEach((errorDetail: any) => {
      const errorMessage = parseErrorDetail(errorDetail)
      errorList.push(errorMessage)
    })
  }

  return errorList
}
