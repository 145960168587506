export const EMAIL_NEEDS_CONFIRMATION_MESSAGE =
  "You must have a confirmed email address to perform that action. Try again."

export const errorFieldToMessageMapping = {
  email: "Please enter a valid email.",
  username: "Please enter a username consisting of only letters, numbers, underscores, and dashes.",
  password: "Please choose a password with at least 7 characters.",
  phone_number: "Invalid phone number.",
  business_ein: "Invalid ein - expecting format like: 01-2345678",
}

export const validationErrorTypeToMessageMapping = {
  "value_error.any_str.min_length": (invalidField, errorDetail) =>
    `${invalidField} must have an input with length of at least ${errorDetail?.ctx?.limit_value || 1}.`,
}

export const errorMessageToUserFriendlyMessageMapping = {
  "Could not validate token credentials.":
    "You must be an authenticated user to perform that action. Login or signup to get started.",
  "Internal server error": "Something went wrong with our application. Please contact support.",
}

export const USER_NOT_FOUND = `Could not find a user with that username.`
export const EVENT_NOT_FOUND = `Could not find a event with that slug.`
export const PROVIDER_NOT_FOUND = `Could not find a provider with that slug.`
