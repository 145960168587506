import { Email, ID, Timestamp, Username } from "types/common"

/**
 * AUTH TYPES
 */
export type UserID = ID
export type PreferenceID = ID

export type LoginCredentials = { email: Email; password: string }
export type SignupCredentials = { username: Username; email: Email; password: string }

export interface UserPreferences {
  id: PreferenceID
  has_set_preferences: boolean
  receives_emails: boolean
  receives_texts: boolean
  created_at: Timestamp
  updated_at: Timestamp
  user_id: UserID
}
export interface UserBase {
  id: UserID
  username: Username
  email: Email
  email_verified: boolean
  created_at: Timestamp
  updated_at: Timestamp
}
// export interface UserAvatar extends UserBase {
//   // profile: Profile
// }
// export interface UserFull extends UserBase {
//   // profile: Profile
//   preferences?: UserPreferences
// }
// export interface UserTruncated extends UserBase {
//   // profile: ProfileID
// }
// export type User = UserFull | UserTruncated

export interface AuthState {
  message: string | null
  error: Error
  isLoading: boolean
  isUpdating: boolean
  isRefreshing: boolean
  preferences: UserPreferences | null
  authActionNameToMetaLookup: {
    // resetEmail: Timestamp | null
    sendPasswordResetEmail: Timestamp | null
    sendConfirmAccountEmail: Timestamp | null
    resetPassword: Timestamp | null
    confirmAccount: Timestamp | null
    changeCredentials: Timestamp | null
  }
}

export interface NextAuthOAuthResponse {
  user?: any
  error?: any
}

export enum AuthActionType {
  SIGN_USER_OUT = "@@auth/SIGN_USER_OUT",

  REQUESTING_PASSWORD_RESET = "@@auth/REQUESTING_PASSWORD_RESET",
  REQUESTING_PASSWORD_RESET_SUCCESS = "@@auth/REQUESTING_PASSWORD_RESET_SUCCESS",
  REQUESTING_PASSWORD_RESET_FAILURE = "@@auth/REQUESTING_PASSWORD_RESET_FAILURE",

  REQUESTING_CONFIRM_PASSWORD_CHANGE = "@@auth/REQUESTING_CONFIRM_PASSWORD_CHANGE",
  REQUESTING_CONFIRM_PASSWORD_CHANGE_SUCCESS = "@@auth/REQUESTING_CONFIRM_PASSWORD_CHANGE_SUCCESS",
  REQUESTING_CONFIRM_PASSWORD_CHANGE_FAILURE = "@@auth/REQUESTING_CONFIRM_PASSWORD_CHANGE_FAILURE",

  UPDATE_USER_EMAIL_AND_USERNAME = "@@auth/UPDATE_USER_EMAIL_AND_USERNAME",
  UPDATE_USER_EMAIL_AND_USERNAME_SUCCESS = "@@auth/UPDATE_USER_EMAIL_AND_USERNAME_SUCCESS",
  UPDATE_USER_EMAIL_AND_USERNAME_FAILURE = "@@auth/UPDATE_USER_EMAIL_AND_USERNAME_FAILURE",

  RESEND_ACCOUNT_CONFIRMATION_EMAIL = "@@auth/RESEND_ACCOUNT_CONFIRMATION_EMAIL",
  RESEND_ACCOUNT_CONFIRMATION_EMAIL_SUCCESS = "@@auth/RESEND_ACCOUNT_CONFIRMATION_EMAIL_SUCCESS",
  RESEND_ACCOUNT_CONFIRMATION_EMAIL_FAILURE = "@@auth/RESEND_ACCOUNT_CONFIRMATION_EMAIL_FAILURE",

  CONFIRM_ACCOUNT = "@@auth/CONFIRM_ACCOUNT",
  CONFIRM_ACCOUNT_SUCCESS = "@@auth/CONFIRM_ACCOUNT_SUCCESS",
  CONFIRM_ACCOUNT_FAILURE = "@@auth/CONFIRM_ACCOUNT_FAILURE",
}

// GENERIC AUTH ACTIONS
export interface ISignUserOut {
  type: AuthActionType.SIGN_USER_OUT
}
export type GenericAuthAction = ISignUserOut

// USER PASSWORD RESET
export interface IUserPasswordReset {
  type: AuthActionType.REQUESTING_PASSWORD_RESET
}
export interface IUserPasswordResetSuccess {
  type: AuthActionType.REQUESTING_PASSWORD_RESET_SUCCESS
}
export interface IUserPasswordResetFailure {
  type: AuthActionType.REQUESTING_PASSWORD_RESET_FAILURE
  error?: Error
}
export type UserPasswordResetAction = IUserPasswordReset | IUserPasswordResetSuccess | IUserPasswordResetFailure

// USER PASSWORD CHANGE
export interface IUserPasswordChange {
  type: AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE
}
export interface IUserPasswordChangeSuccess {
  type: AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE_SUCCESS
}
export interface IUserPasswordChangeFailure {
  type: AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE_FAILURE
  error?: Error
}
export type UserPasswordChangeAction = IUserPasswordChange | IUserPasswordChangeSuccess | IUserPasswordChangeFailure

export interface IUpdateUserEmailAndUsername {
  type: AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME
}
export interface IUpdateUserEmailAndUsernameSuccess {
  type: AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME_SUCCESS
}
export interface IUpdateUserEmailAndUsernameFailure {
  type: AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME_FAILURE
  error?: Error
}
export type UpdateUserEmailAndUsernameAction =
  | IUpdateUserEmailAndUsername
  | IUpdateUserEmailAndUsernameSuccess
  | IUpdateUserEmailAndUsernameFailure

// RESEND ACCOUNT CONFIRMATION EMAIL
export interface IResendAccountConfirmationEmail {
  type: AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL
}
export interface IResendAccountConfirmationEmailSuccess {
  type: AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL_SUCCESS
  data: string
}
export interface IResendAccountConfirmationEmailFailure {
  type: AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL_FAILURE
  error?: Error
}
export type ResendAccountConfirmationEmailAction =
  | IResendAccountConfirmationEmail
  | IResendAccountConfirmationEmailSuccess
  | IResendAccountConfirmationEmailFailure

// CONFIRM ACCOUNT
export interface IConfirmAccount {
  type: AuthActionType.CONFIRM_ACCOUNT
}
export interface IConfirmAccountSuccess {
  type: AuthActionType.CONFIRM_ACCOUNT_SUCCESS
  data: string
}
export interface IConfirmAccountFailure {
  type: AuthActionType.CONFIRM_ACCOUNT_FAILURE
  error?: Error
}
export type ConfirmAccountAction = IConfirmAccount | IConfirmAccountSuccess | IConfirmAccountFailure

export type AuthAction =
  | GenericAuthAction
  | UserPasswordResetAction
  | UserPasswordChangeAction
  | UpdateUserEmailAndUsernameAction
  | ResendAccountConfirmationEmailAction
  | ConfirmAccountAction
