// import * as R from "ramda"
// import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"
import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"
// import { selectListOfReviewsFromReviewIdList } from "models/reviews"
import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
// import { normalizeEventsFromServer, isEventInstance, extractEventsFromProvider } from "utils/events"
// import { isString, isNumber } from "utils/parseUtils"
// import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { ToastType } from "types/ui"
import {
  AuthActionType,
  AuthAction,
  UserPasswordResetAction,
  ResendAccountConfirmationEmailAction,
  ConfirmAccountAction,
  UserPasswordChangeAction,
  UpdateUserEmailAndUsernameAction,
} from "types/auth"
import { Email, Username } from "types/common"

export default function authReducer(state = initialState.auth, action: AuthAction) {
  switch (action.type) {
    case AuthActionType.REQUESTING_PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case AuthActionType.REQUESTING_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        error: null,
        message: "Password reset email sent.",
        isLoading: false,
        authActionNameToMetaLookup: {
          ...state.authActionNameToMetaLookup,

          sendPasswordResetEmail: new Date().toISOString(),
        },
      }
    case AuthActionType.REQUESTING_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        error: action.error,
        message: "Password reset email failed. Contact support.",
        isLoading: false,
      }
    // CHANGE PASSWORD STUFF
    case AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE:
      return {
        ...state,
        isLoading: true,
      }
    case AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authActionNameToMetaLookup: {
          ...state.authActionNameToMetaLookup,

          resetPassword: new Date().toISOString(),
        },
      }
    case AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL:
      return {
        ...state,
      }
    case AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        message: action.data,
        authActionNameToMetaLookup: {
          ...state.authActionNameToMetaLookup,

          sendConfirmAccountEmail: new Date().toISOString(),
        },
      }
    case AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case AuthActionType.CONFIRM_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        error: null,
        message: null,
      }
    case AuthActionType.CONFIRM_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        message: action.data,
        authActionNameToMetaLookup: {
          ...state.authActionNameToMetaLookup,

          confirmAccount: new Date().toISOString(),
        },
      }
    case AuthActionType.CONFIRM_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        message: null,
      }
    case AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME:
      return {
        ...state,
        isUpdating: true,
      }
    case AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME_SUCCESS:
      return {
        ...state,
        // isUpdating: false,  // do this when user returns
        error: null,
        authActionNameToMetaLookup: {
          ...state.authActionNameToMetaLookup,
          changeCredentials: new Date().toISOString(),
        },
      }
    case AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      }
    // case AuthActionType.RESET_AUTH_STATE:
    //   return initialState.auth
    case AuthActionType.SIGN_USER_OUT:
      return {
        ...initialState.auth,
        userLoaded: true,
      }
    default:
      return state
  }
}

const signUserOut = () => ({ type: AuthActionType.SIGN_USER_OUT })

export type PasswordResetEmailParams = { email: Email }
export type PasswordResetEmailResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UserPasswordResetAction
>
const sendPasswordResetEmail = ({ email }: PasswordResetEmailParams): PasswordResetEmailResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/auth/password-recovery/${email}/`,
        method: "POST",
        types: {
          REQUEST: AuthActionType.REQUESTING_PASSWORD_RESET,
          SUCCESS: AuthActionType.REQUESTING_PASSWORD_RESET_SUCCESS,
          FAILURE: AuthActionType.REQUESTING_PASSWORD_RESET_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({
          success: true,
          status: res.status,
          message: `If that email exists in our system, you'll receive a message shortly.`,
        }),
        onFailure: (res) => ({ success: false, status: res.status, error: res.error }),
      })
    )
  }
}

export type ResendConfirmationEmailResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  ResendAccountConfirmationEmailAction
>
const resendConfirmAccountEmail = (): ResendConfirmationEmailResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/auth/resend-account-confirmation-email/`,
        method: "POST",
        types: {
          REQUEST: AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL,
          SUCCESS: AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL_SUCCESS,
          FAILURE: AuthActionType.RESEND_ACCOUNT_CONFIRMATION_EMAIL_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
      })
    )
  }
}

export type ConfirmAccountParams = { token: string }
export type ConfirmAccountResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  ConfirmAccountAction
>
const confirmAccount = ({ token }: ConfirmAccountParams): ConfirmAccountResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/auth/confirm-account/`,
        method: "POST",
        types: {
          REQUEST: AuthActionType.CONFIRM_ACCOUNT,
          SUCCESS: AuthActionType.CONFIRM_ACCOUNT_SUCCESS,
          FAILURE: AuthActionType.CONFIRM_ACCOUNT_FAILURE,
        },
        options: {
          data: { token },
          params: {},
        },
        onSuccess: (res) => {
          // dispatch(Actions.getUserFromAuthToken())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const failureToast = {
            // type: "danger",
            type: ToastType.DANGER,
            title: "Unable to confirm account",
            contents: `We were not able to confirm your email address. Please try again.`,
          }
          dispatch(uiActions.addUiToast({ toast: failureToast }))

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type PasswordChangeParams = { token: string; password: string }
export type PasswordChangeResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UserPasswordChangeAction
>
const sendPasswordChangeRequest = ({ password, token }: PasswordChangeParams): PasswordChangeResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/auth/reset-password/`,
        method: "POST",
        types: {
          REQUEST: AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE,
          SUCCESS: AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE_SUCCESS,
          FAILURE: AuthActionType.REQUESTING_CONFIRM_PASSWORD_CHANGE_FAILURE,
        },
        options: {
          data: { token, new_password: password },
          params: {},
        },
        onSuccess: (res) => {
          // dispatch(Actions.getUserFromAuthToken())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const failureToast = {
            // type: "danger",
            type: ToastType.DANGER,
            title: "Unable to change password.",
            contents: `That password change request is invalid or has expired. Please try again or contact support.`,
          }
          dispatch(uiActions.addUiToast({ toast: failureToast }))

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type UpdateEmailAndUsernameParams = { email?: Email; username?: Username }
export type UpdateEmailAndUsernameResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UpdateUserEmailAndUsernameAction
>
const updateEmailAndUsername = ({ email, username }: UpdateEmailAndUsernameParams): UpdateEmailAndUsernameResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/users/me/`,
        method: `PUT`,
        types: {
          REQUEST: AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME,
          SUCCESS: AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME_SUCCESS,
          FAILURE: AuthActionType.UPDATE_USER_EMAIL_AND_USERNAME_FAILURE,
        },
        options: {
          data: { user_update: { email, username } },
          params: {},
        },
        onSuccess: (res) => {
          // const token = res?.data?.access_token

          // make sure to store the token when they're logged in
          // setToken(token)

          const toast = {
            title: "Success!",
            contents: "Email and/or username changed. You may need to confirm your email address again.",
            // type: "success",
            type: ToastType.SUCCESS,
          }
          dispatch(uiActions.addUiToast({ toast }))

          // return dispatch(Actions.getUserFromAuthToken())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)
          // console.log(errorMessages)
          errorMessages.forEach((error) => {
            const toast = {
              title: "Unable to change email or username.",
              contents: makeUserFriendlyErrorMessage(error),
              // type: "danger",
              type: ToastType.DANGER,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export const Actions = {
  signUserOut,
  confirmAccount,
  sendPasswordResetEmail,
  resendConfirmAccountEmail,
  sendPasswordChangeRequest,
  updateEmailAndUsername,
}
