import { getSession } from "next-auth/client"

const extractTokenFromSession = (session): string | null => {
  const deeplyNestedToken = session?.userInfo?.user?.userInfo?.user?.velupAccessToken
  if (deeplyNestedToken) return deeplyNestedToken

  const standardNestedToken = session?.userInfo?.user?.velupAccessToken

  return standardNestedToken || session?.userInfo?.velupAccessToken
}

export const getToken = async (): Promise<string | null> => {
  try {
    const session = await getSession()

    return extractTokenFromSession(session)
  } catch (err) {
    return null
  }
}
