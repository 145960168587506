// import { AttendanceTrackingState } from "models/atte/attendanceTracking"
// import { PersonalizationState } from "models/personalization"
// import { FavoritesState } from "types/favorites"
import { AuthState } from "types/auth"
import { EventsState } from "types/events"
import { FeedState } from "types/feed"
import { AttendanceTrackingState, FavoritesState } from "types/personalization"
import { NotificationsState } from "types/notifications"
import { ProfileState } from "types/user"
import { ProvidersState } from "types/providers"
import { ReviewsState } from "types/reviews"
import { SearchState } from "types/search"
import { UiState } from "types/ui"
import { WizardState } from "types/wizard"

export interface StateShape {
  auth: AuthState
  attendanceTracking: AttendanceTrackingState
  favorites: FavoritesState
  // personalization: PersonalizationState
  events: EventsState
  feed: FeedState
  // personal
  notifications: NotificationsState
  profiles: ProfileState
  providers: ProvidersState
  reviews: ReviewsState
  search: SearchState
  ui: UiState
  wizard: WizardState
  _persist?: Record<string, any>
}

const initialState: StateShape = {
  /*=================
          AUTH
  ===================*/
  auth: {
    message: null,
    error: null,
    isLoading: false,
    isUpdating: false,
    isRefreshing: false,
    preferences: null,
    authActionNameToMetaLookup: {
      sendPasswordResetEmail: null,
      sendConfirmAccountEmail: null,
      resetPassword: null,
      confirmAccount: null,
      changeCredentials: null,
    },
  },
  /*=================
       ATTENDANCE
  ===================*/
  attendanceTracking: {
    isLoading: false,
    error: null,
    // data: {},
    events: {},
  },
  /*=================
        FAVORITES
  ===================*/

  favorites: {
    isLoading: false,
    error: null,
    events: {},
  },
  /*=================
     PERSONALIZATION
  ===================*/
  // personalization: {
  //   isLoading: false,
  //   isUpdating: false,
  //   error: null,
  //   hasSetPreferences: false,
  // },
  /*=================
        EVENTS
  ===================*/
  events: {
    isCreating: false,
    isLoading: false,
    isUpdating: false,
    error: null,
    data: {},
    eventSlugToIdLookup: {},
    eventSlugToMetaLookup: {},
  },
  /*=================
        FEED
  ===================*/
  feed: {
    lastFetched: null,
    isLoading: false,
    error: null,
    data: {},
    hasNext: {
      main: false,
      events: false,
      providers: false,
      reviews: false,
    },
  },
  /*=================
      NOTIFICATIONS
  ===================*/
  notifications: {
    isLoading: false,
    isDeleting: false,
    error: null,
    invitations: {},
  },
  /*=================
        PROFILES
  ===================*/
  profiles: {
    isFetching: false,
    isUpdating: false,
    error: null,
    data: {},
    usernameToIdLookup: {},
    usernameToMetaLookup: {},
  },
  /*=================
        PROVIDERS
  ===================*/
  providers: {
    isLoading: false,
    creatingState: null,
    updatingState: null,
    fetchingState: null,
    deletingState: null,
    // isCreating: false,
    // isLoading: false,
    // isUpdating: false,
    error: null,
    data: {},
    providerSlugToIdLookup: {},
    providerSlugToMetaLookup: {},
  },
  /*=================
        REVIEWS
  ===================*/
  reviews: {
    isCreating: false,
    isFetching: false,
    isUpdating: false,
    error: null,
    data: {},
    reviewIdToMetaLookup: {},
  },
  /*=================
        SEARCH
  ===================*/
  search: {
    searchType: `events`,
    lastUserQuery: null,
    lastEventQuery: null,
    lastProviderQuery: null,
    // userResults: null,
    // providerResults: null,
    // eventResults: null,
    // results: null,
    filtersHaveChanged: true,
    userHasClickedSearch: true,
    // isLoading: false,
    // error: null,
    date: {
      start: "", // to deal with date inputs
      end: "", // to deal with date inputs
    },
    reviews: {
      has_reviews: false,
    },
    rating: {
      selected: null,
    },
    top: {
      selected: null,
    },
    location: {
      mi_radius: "",
      center_long: null,
      center_lat: null,
      google_location: null,
    },
    price: {
      selected: null,
    },
    focus: {
      selected: null,
    },
    topUsers: {
      selected: null,
    },
  },
  /*=================
          UI
  ===================*/
  ui: {
    dropdownMenuOpen: null,
    navbarDropdowns: {
      Events: false,
      Providers: false,
      Reviews: false,
      Explore: false,
      Search: false,
    },
    navbarSubMenu: {
      active: {
        Events: "featured",
        Providers: "featured",
        Reviews: "write",
        Explore: "coaches",
        Search: "events",
      },
    },
    modalOpen: {
      feedOnboarding: true,
    },
    navbarSearch: "",
    mobileOpen: false,
    toastList: [],
  },
  /*====================
        WIZARD
  ======================*/
  wizard: {
    initialized: false,
    feedOnboarding: {
      currentStep: `start`,
      interests: {},
      roles: {},
      profile: {},
    },
    eventsCreate: {
      currentStep: `eventInfo`,
    },
    eventsHomeCreate: {
      currentStep: `organization`,
    },
    eventsUpdate: {
      currentStep: `eventInfo`,
    },
    providersHomeCreate: {
      currentStep: `basic`,
    },
    providerToStripe: {
      currentStep: `legalInfo`,
    },
  },
}

export default initialState
