import * as R from "ramda"
import { Slug, Username } from "types/common"
import { ReviewID } from "types/reviews"

export const getQueryParamsFromPopupUrl = (url: string): any => {
  const queryString = url.split("?").slice(1).join("")
  const pairsString = queryString[0] === "?" ? queryString.slice(1) : queryString
  const pairs = pairsString.split("&").map((str) => str.split("=").map(decodeURIComponent))
  return pairs.reduce((acc, [key, value]) => (key ? { ...acc, [key]: value } : acc), {})
}

/**
 *
 *
 * @param {object} params - object representation of query parameters attached to request
 * @return {string} string representation of query params - example: "?q=rabbit&category=small"
 */
export const formatQueryParams = (params: Record<string, any>): string => {
  return R.isEmpty(params) ? "" : `?${new URLSearchParams(params).toString()}`
}
/**
 *  Format API request paths
 *
 * @param {string} path -
 */
export const formatAPIUrlPath = (path: string): string => {
  let adjustedPath = path

  // bookend path with forward slashes
  if (adjustedPath.charAt(0) !== "/") {
    adjustedPath = "/" + adjustedPath
  }
  if (adjustedPath.charAt(adjustedPath.length - 1) !== "/") {
    adjustedPath = adjustedPath + "/"
  }

  const fullPath = `${adjustedPath}`

  return fullPath
}

/**
 * Formats API request URLs
 *
 * @param {string} url - string representing relative path of api endpoint
 * @param {object} params - query params to format at end of url
 */
export const formatUrl = (url: string, params?: any): string => {
  const queryParams = formatQueryParams(params)
  const fullPath = `${formatAPIUrlPath(url)}${queryParams}`

  // We send all api request to the same host as the FE server, they
  // are then proxied to the real api securely behind the scenes
  url = `/api${fullPath}`
  // console.log(url)
  return url
}

export const capitalize = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1)
}

export const truncateStrByWordNumber = (str: string, maxWordCount: number = 100): string => {
  if (getNumWords(str) < maxWordCount) return str
  return str.split(" ").slice(0, maxWordCount).join(" ") + "..."
}

export const getNumWords = (str: string): number => {
  return str.split(" ").length
}

interface ConstructStripeOAuthUrlParams {
  client_id: string
  response_type: string
  scope: string
  state: string
  stripe_info: Record<string, any>
  auth_url: string
}
export const constructStripeOAuthUrl = ({
  client_id,
  response_type,
  scope,
  state,
  stripe_info,
  auth_url,
}: ConstructStripeOAuthUrlParams): string => {
  const url = `${auth_url}${formatQueryParams({ client_id, response_type, scope, state })}`
  const stripe_user = Object.keys(stripe_info)
    .filter((k) => stripe_info[k])
    .map((key) => `stripe_user[${key}]=${stripe_info[key]}`)
    .join("&")
  return `${url}&${stripe_user}`
}

export const URL_TREE = {
  id: 0,
  root: "",
  about: { root: `about-us`, id: 1 },
  login: { root: `login`, id: 2 },
  feed: { root: `feed`, id: 3 },
  signout: { root: `signout`, id: 4 },
  contact_us: { root: `contact-us`, id: 5 },
  settings: { root: `settings`, id: 6 },
  register: { root: `register`, id: 7 },
  confirm_account: { root: `confirm-account`, id: 8 },
  password_change: { root: `password-change`, id: 9 },
  password_reset: { root: `password-reset`, id: 10 },
  notifications: { root: `notifications`, id: 11 },
  terms_of_service: { root: `terms-of-service`, id: 12 },
  /* BLOG */
  blog: {
    root: `blog`,
    id: -1,
    write_for_us: { root: `write-for-us`, id: -2 },
  },
  /* SEARCH */
  search: {
    id: 13,
    root: `search`,
    events: { root: `events`, id: 14 },
    providers: { root: `providers`, id: 15 },
    users: { root: `users`, id: 16 },
  },
  /* REVIEWS */
  reviews: {
    id: 17,
    root: `reviews`,
    edit: { root: ({ reviewId }: { reviewId: ReviewID }) => `edit/${reviewId}`, id: 18 },
    s: { root: ({ reviewId }: { reviewId: ReviewID }) => `s/${reviewId}`, id: 19 },
  },
  /* PROVIDERS */
  providers: {
    id: 20,
    root: `providers`,
    s: { root: ({ providerSlug }: { providerSlug: Slug }) => `s/${providerSlug}`, id: 21 },
    /* MANAGEMENT PORTAL */
    m: {
      id: 22,
      root: `m`,
      account: { root: `account`, id: 23 },
      settings: { root: `settings`, id: 24 },
      team: { root: `team`, id: 25 },
      events: {
        id: 26,
        root: `events`,
        create: { root: `create`, id: 27 },
        analyze: {
          id: 28,
          root: `analyze`,
          eventSlug: { root: ({ eventSlug }: { eventSlug: Slug }) => eventSlug, id: 29 },
        },
        edit: {
          id: 30,
          root: `edit`,
          eventSlug: { root: ({ eventSlug }: { eventSlug: Slug }) => eventSlug, id: 31 },
        },
        registrations: {
          id: 32,
          root: `registrations`,
          eventSlug: { root: ({ eventSlug }: { eventSlug: Slug }) => eventSlug, id: 33 },
        },
      },
      payments: {
        id: 34,
        root: `payments`,
        stripe: {
          id: 35,
          root: `stripe`,
          confirm: { root: `confirm`, id: 36 },
          refresh: { root: `refresh`, id: 37 },
        },
      },
    },
  },
  /* PROFILES */
  profiles: {
    id: 38,
    root: `profiles`,
    username: { root: ({ username }: { username: Username }) => username, id: 39 },
    // username: (username: Username) => ``,
    edit: { root: `edit`, id: 40 },
  },
  /* EVENTS */
  events: {
    id: 41,
    root: `events`,
    registrations: { root: ({ eventSlug }: { eventSlug: Slug }) => `registrations/${eventSlug}`, id: 42 },
    s: { root: ({ eventSlug }: { eventSlug: Slug }) => `s/${eventSlug}`, id: 43 },
  },
  /* LINKEDIN */
  linkedin: {
    id: 44,
    root: `linkedin`,
  },
  /* LINKEDIN */
  data_explorer: {
    id: 45,
    root: `data-explorer`,
    demographics: { root: `demographics`, id: 46 },
    principals: {
      id: 47,
      root: `principals`,
      perception: { root: `perception`, id: 48 },
      salary: { root: `salary`, id: 49 },
    },
    professional_development: {
      id: 50,
      root: `professional-development`,
      admin: { root: `admin`, id: 51 },
    },
    school_environment: {
      id: 52,
      root: `school-environment`,
      attendance: { root: `attendance`, id: 53 },
      start_time: { root: `start-time`, id: 54 },
    },
    school_services: {
      id: 55,
      root: `school-services`,
      special_education: { root: `special-education`, id: 56 },
      title1: { root: `title-1`, id: 57 },
    },
    states_and_districts: {
      id: 58,
      root: `states-and-districts`,
      district_finances: { root: `district-finances`, id: 59 },
    },
    teacher_compensation: {
      id: 60,
      root: `teacher-compensation`,
      incentives_and_benefits: { root: `incentives-and-benefits`, id: 61 },
    },
    teacher_perception: { root: `teacher-perception`, id: 62 },
    teacher_preparation: { root: `teacher-preparation`, id: 63 },
  },
}

type PathObjParam = { root: string | any; id: number; [key: string]: any }
function traverseUrlTreeAndBuildPath(pathObj: PathObjParam, params: Record<string, any> = {}): string {
  // Given an arbitrarily nested obj with a root and id key,
  // recursively traverse the url tree and find the location of that obj.
  // Use that to compose an actual url path

  const { id } = pathObj

  // recursively traverse the tree to create the path
  const path = findPathToId(id)

  // construct route from path
  return composeRouteFromPath(path, params)
}

type StringOrFunction = string | ((obj: Record<string, any>) => string)
function findPathToId(targetId: number, path: StringOrFunction[] = []): StringOrFunction[] {
  // BASE CASE
  if (R.pathSatisfies((id) => id === targetId, [...(path as string[]), "id"], URL_TREE)) {
    return path
  }
  // GET ANY KEYS THAT MIGHT NEED RECURSING OVER
  const validKeys = R.keys(R.omit(["id", "root"], R.pathOr({}, path as string[], URL_TREE)))

  for (let i = 0; i < validKeys.length; i++) {
    // ADD POTENTIAL NEXT ROUTE TO PATH AND RECURSIVELY SOLVE
    const result = findPathToId(targetId, [...(path as string[]), validKeys[i]])
    if (result) {
      return result
    }
  }

  return null
}

function extraRootFromPath(path: StringOrFunction[], params: Record<string, any>): string {
  // IF ROOT IS SIMPLY A STRING, JUST RETURN THAT
  if (R.pathSatisfies((root) => typeof root === "string", [...(path as string[]), "root"], URL_TREE)) {
    return R.path([...(path as string[]), "root"], URL_TREE) // path[path.length - 1]
  }

  // HANDLE EDGE CASE WHERE TERMINAL ROOT IS FUNCTION
  if (R.pathSatisfies((root) => typeof root === "function", [...(path as string[]), "root"], URL_TREE)) {
    const func = R.path([...(path as string[]), "root"], URL_TREE)
    // console.log(func)
    if (typeof func === "function") {
      return func(params)
    }
  }

  return R.path([...(path as string[]), "root"], URL_TREE)
}

function composeRouteFromPath(path: StringOrFunction[], params: Record<string, any>): string {
  let newPath = ``

  for (let i = 0; i < path.length; i++) {
    newPath += `/`
    const currentPath = path.slice(0, i + 1)
    newPath += extraRootFromPath(currentPath, params)
  }

  newPath += `/`
  return newPath
}

export const constructPathFromUrlTree = (pathObj: PathObjParam, params: Record<string, any> = {}): string => {
  return traverseUrlTreeAndBuildPath(pathObj, params)
}

// expose a bunch of path shortcuts to prevent having to reconstruct them a bunch
export const URLS = {
  about: constructPathFromUrlTree(URL_TREE.about),
  blog: constructPathFromUrlTree(URL_TREE.blog),
  confirm_account: constructPathFromUrlTree(URL_TREE.confirm_account),
  contact: constructPathFromUrlTree(URL_TREE.contact_us),
  /* DATA EXPLORER */
  data_explorer: constructPathFromUrlTree(URL_TREE.data_explorer),
  data_explorer_demographics: constructPathFromUrlTree(URL_TREE.data_explorer.demographics),
  data_explorer_principals: constructPathFromUrlTree(URL_TREE.data_explorer.principals),
  data_explorer_principals_perception: constructPathFromUrlTree(URL_TREE.data_explorer.principals.perception),
  data_explorer_principals_salary: constructPathFromUrlTree(URL_TREE.data_explorer.principals.salary),
  data_explorer_professional_development: constructPathFromUrlTree(URL_TREE.data_explorer.professional_development),
  data_explorer_professional_development_admin: constructPathFromUrlTree(
    URL_TREE.data_explorer.professional_development.admin
  ),
  data_explorer_school_environment: constructPathFromUrlTree(URL_TREE.data_explorer.school_environment),
  data_explorer_school_environment_attendance: constructPathFromUrlTree(
    URL_TREE.data_explorer.school_environment.attendance
  ),
  data_explorer_school_environment_start_time: constructPathFromUrlTree(
    URL_TREE.data_explorer.school_environment.start_time
  ),
  data_explorer_school_services: constructPathFromUrlTree(URL_TREE.data_explorer.school_services),
  data_explorer_school_services_special_education: constructPathFromUrlTree(
    URL_TREE.data_explorer.school_services.special_education
  ),
  data_explorer_school_services_title1: constructPathFromUrlTree(URL_TREE.data_explorer.school_services.title1),
  data_explorer_states_and_districts: constructPathFromUrlTree(URL_TREE.data_explorer.states_and_districts),
  data_explorer_states_and_districts_finances: constructPathFromUrlTree(
    URL_TREE.data_explorer.states_and_districts.district_finances
  ),
  data_explorer_teacher_compensation: constructPathFromUrlTree(URL_TREE.data_explorer.teacher_compensation),
  data_explorer_teacher_compensation_incentives_and_benefits: constructPathFromUrlTree(
    URL_TREE.data_explorer.teacher_compensation.incentives_and_benefits
  ),
  data_explorer_teacher_perception: constructPathFromUrlTree(URL_TREE.data_explorer.teacher_perception),
  data_explorer_teacher_preparation: constructPathFromUrlTree(URL_TREE.data_explorer.teacher_preparation),
  /* EVENTS */
  events: constructPathFromUrlTree(URL_TREE.events),
  event_by_slug: (eventSlug: Slug) => constructPathFromUrlTree(URL_TREE.events.s, { eventSlug }),
  event_registration_by_slug: (eventSlug: Slug) =>
    constructPathFromUrlTree(URL_TREE.events.registrations, { eventSlug }),

  feed: constructPathFromUrlTree(URL_TREE.feed),
  home: constructPathFromUrlTree(URL_TREE),
  login: constructPathFromUrlTree(URL_TREE.login),
  notifications: constructPathFromUrlTree(URL_TREE.notifications),
  password_reset: constructPathFromUrlTree(URL_TREE.password_reset),
  password_change: constructPathFromUrlTree(URL_TREE.password_change),
  /* PROFILES */
  profiles: constructPathFromUrlTree(URL_TREE.profiles),
  profiles_edit: constructPathFromUrlTree(URL_TREE.profiles.edit),
  profile_by_username: (username: Username) => constructPathFromUrlTree(URL_TREE.profiles.username, { username }),
  /* PROVIDERS */
  providers: constructPathFromUrlTree(URL_TREE.providers),
  provider_by_slug: (providerSlug: Slug) => constructPathFromUrlTree(URL_TREE.providers.s, { providerSlug }),
  // manage providers
  providers_manage: constructPathFromUrlTree(URL_TREE.providers.m),
  providers_manage_events: constructPathFromUrlTree(URL_TREE.providers.m.events),
  providers_manage_events_create: constructPathFromUrlTree(URL_TREE.providers.m.events.create),
  providers_manage_events_analyze: constructPathFromUrlTree(URL_TREE.providers.m.events.analyze),
  providers_manage_events_analyze_by_slug: (eventSlug: Slug) =>
    constructPathFromUrlTree(URL_TREE.providers.m.events.analyze.eventSlug, { eventSlug }),
  providers_manage_events_edit: constructPathFromUrlTree(URL_TREE.providers.m.events.edit),
  providers_manage_events_registrations: constructPathFromUrlTree(URL_TREE.providers.m.events.registrations),
  providers_manage_events_registrations_by_slug: (eventSlug: Slug) =>
    constructPathFromUrlTree(URL_TREE.providers.m.events.registrations.eventSlug, { eventSlug }),
  providers_manage_teams: constructPathFromUrlTree(URL_TREE.providers.m.team),
  providers_manage_payments: constructPathFromUrlTree(URL_TREE.providers.m.payments),
  providers_manage_account: constructPathFromUrlTree(URL_TREE.providers.m.account),
  providers_manage_settings: constructPathFromUrlTree(URL_TREE.providers.m.settings),

  register: constructPathFromUrlTree(URL_TREE.register),
  /* REVIEWS */
  reviews: constructPathFromUrlTree(URL_TREE.reviews),
  reviews_edit: (reviewId: ReviewID) => constructPathFromUrlTree(URL_TREE.reviews.edit, { reviewId }),
  reviews_by_id: (reviewId: ReviewID) => constructPathFromUrlTree(URL_TREE.reviews.s, { reviewId }),
  /* SEARCH */
  search: constructPathFromUrlTree(URL_TREE.search),
  search_events: constructPathFromUrlTree(URL_TREE.search.events),
  search_providers: constructPathFromUrlTree(URL_TREE.search.providers),
  search_users: constructPathFromUrlTree(URL_TREE.search.users),

  settings: constructPathFromUrlTree(URL_TREE.settings),
  signout: constructPathFromUrlTree(URL_TREE.signout),
  terms: constructPathFromUrlTree(URL_TREE.terms_of_service),
}
