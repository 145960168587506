import { StandardSizes, Sizes } from "types/common"
import { ThunkAction } from "redux-thunk"

export type AvatarSize = StandardSizes

export enum BadgeType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ACCENT = "accent",
}

export type ButtonType = "primary" | "secondary" | "danger" | "plain"
// export type ButtonSize = "xs" | "sm" | "md" | "lg" | "xl"
export type ButtonSize = StandardSizes
export type ButtonState = "default" | "hover" | "inactive" | "destructive"

// export type ChipType = "primary" | "secondary" | "accent"

export enum ChipType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ACCENT = "accent",
}
export type ChipSize = StandardSizes

export type DropdownItem = { label: string; value: string }

export type KeypadSizes = "sm" | "md" | "lg" | "xl"

export type NotificationDotSize = "md"
export type NotificationDotType = "accent" | "warning" | "primary" | "success"

export type QuickLink = { label: string; path: string }

export type SpacerSize = Sizes

export type SpinnerSize = "xs" | "sm" | "md" | "lg" | "full-size"
export type SpinnerType = "primary" | "secondary" | "accent"

export type ToastID = string
// export type ToastType = "danger" | "info" | "success" | "warning"

export enum ToastType {
  DANGER = "danger",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

export interface Toast {
  id?: ToastID
  title: string
  contents: string
  type: ToastType
  link?: string
  primaryAction?: { label: string; value: string }
  secondaryAction?: { label: string; value: string }
}
export interface IIcon {
  size?: number
  circle?: boolean
  fill?: any
  stroke?: string
  strokeWidth?: number
  strokeLinecap?: "square" | "butt" | "round" | "inherit"
  strokeMiterlimit?: number
  strokeLinejoin?: "miter" | "round" | "inherit" | "bevel"
  style?: Record<string, any>
  className?: string
  fillBackground?: any
  fillText?: any
  fillRule?: "nonzero" | "evenodd" | "inherit"
  alt?: string
  [key: string]: any
}

export type DropdownOption = {
  value: any
  label: string
}
export interface NavbarDropdowns {
  Events: boolean
  Providers: boolean
  Reviews: boolean
  Explore: boolean
  Search: boolean
}
export interface NavbarSubMenu {
  Events: string
  Providers: string
  Reviews: string
  Explore: string
  Search: string
}

type DropdownMenu = "grid" | "bell" | "auth"
export interface UiState {
  dropdownMenuOpen?: DropdownMenu
  navbarDropdowns: NavbarDropdowns
  navbarSubMenu: {
    active: NavbarSubMenu
  }
  modalOpen: Record<string, boolean>
  navbarSearch: string
  mobileOpen: boolean
  toastList: Toast[]
}

export enum UiActionType {
  NAVIGATE_INTERNAL = "@@ui/NAVIGATE_INTERNAL",

  ADD_UI_TOAST = "@@ui/ADD_UI_TOAST",
  REMOVE_UI_TOAST = "@@ui/REMOVE_UI_TOAST",

  HANDLE_NAVBAR_SEARCH = "@@ui/HANDLE_NAVBAR_SEARCH",
  SET_NAVBAR_SEARCH_VALUE = "@@ui/SET_NAVBAR_SEARCH_VALUE",

  TOGGLE_NAVBAR_DROPDOWN = "@@ui/TOGGLE_NAVBAR_DROPDOWN",
  CLOSE_SINGLE_NAVBAR_DROPDOWN = "@@ui/CLOSE_SINGLE_NAVBAR_DROPDOWN",
  OPEN_SINGLE_NAVBAR_DROPDOWN = "@@ui/OPEN_SINGLE_NAVBAR_DROPDOWN",
  CLOSE_ALL_NAVBAR_DROPDOWNS = "@@ui/CLOSE_ALL_NAVBAR_DROPDOWNS",

  OPEN_MOBILE_NAV = "@@ui/OPEN_MOBILE_NAV",
  CLOSE_MOBILE_NAV = "@@ui/CLOSE_MOBILE_NAV",

  OPEN_MODAL = "@@ui/OPEN_MODAL",
  CLOSE_MODAL = "@@ui/CLOSE_MODAL",

  SET_SUBMENU_ACTIVE_SELECTION = "@@ui/SET_SUBMENU_ACTIVE_SELECTION",

  CLEAR_UI_STATE = "@@iu/CLEAR_UI_STATE",
}

// GENERIC
export interface INavigateInternal {
  type: UiActionType.NAVIGATE_INTERNAL
}
export interface IClearUiState {
  type: UiActionType.CLEAR_UI_STATE
}
export type GenericUiAction = INavigateInternal | IClearUiState

// UI TOAST
export interface IAddUiToast {
  type: UiActionType.ADD_UI_TOAST
  toast: Toast
}
export interface IRemoveUiToast {
  type: UiActionType.REMOVE_UI_TOAST
  toastId: ToastID
}
export type UiToastAction = IAddUiToast | IRemoveUiToast

// NAVBAR SEARCH
export interface IHandleNavbarSearch {
  type: UiActionType.HANDLE_NAVBAR_SEARCH
}
export interface ISetNavbarSearchValue {
  type: UiActionType.SET_NAVBAR_SEARCH_VALUE
  value: string
}
export type NavbarSearchAction = IHandleNavbarSearch | ISetNavbarSearchValue

// NAVBAR DROPDOWNS
export interface IToggleNavbarDropdown {
  type: UiActionType.TOGGLE_NAVBAR_DROPDOWN
  label: string
}
export interface IOpenSingleNavbarDropdown {
  type: UiActionType.OPEN_SINGLE_NAVBAR_DROPDOWN
  label: string
}
export interface ICloseSingleNavbarDropdown {
  type: UiActionType.CLOSE_SINGLE_NAVBAR_DROPDOWN
  label: string
}
export interface ICloseAllNavbarDropdowns {
  type: UiActionType.CLOSE_ALL_NAVBAR_DROPDOWNS
}
export interface ISetNavbarSubmenuActiveSelection {
  type: UiActionType.SET_SUBMENU_ACTIVE_SELECTION
  subMenu: string
  label: string
}
export type NavbarDropdownAction =
  | IToggleNavbarDropdown
  | ICloseSingleNavbarDropdown
  | IOpenSingleNavbarDropdown
  | ICloseAllNavbarDropdowns
  | ISetNavbarSubmenuActiveSelection

// MOBILE NAVBAR
export interface IOpenMobileNav {
  type: UiActionType.OPEN_MOBILE_NAV
}
export interface ICloseMobileNav {
  type: UiActionType.CLOSE_MOBILE_NAV
}
export type MobileNavbarAction = IOpenMobileNav | ICloseMobileNav

// MODALS
export interface IOpenModal {
  type: UiActionType.OPEN_MODAL
  data: string
}
export interface ICloseModal {
  type: UiActionType.CLOSE_MODAL
  data: string
}
export type ModalAction = IOpenModal | ICloseModal

export type UiAction =
  | GenericUiAction
  | UiToastAction
  | NavbarSearchAction
  | NavbarDropdownAction
  | MobileNavbarAction
  | ModalAction

export type UiToastThunkAction = ThunkAction<UiToastAction, any, void, UiToastAction>
